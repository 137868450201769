const apiUrl = 'https://api.pllueca.xyz'
export const socketUrl = "wss://api.pllueca.xyz/ws"


// const apiUrl = 'http://0.0.0.0:8084'
// export const socketUrl = "ws://0.0.0.0:8084/ws"

export type emojiAPIParams = {
    foreground: string
    background: string
    withBorder: boolean
    border: string
    borderSize?: number
    vertical?: boolean
}



export async function getEmojiText(word: string, emojiParams: emojiAPIParams) {
    const { foreground, background, withBorder, border, borderSize = 1 } = emojiParams
    if (word === "") {
        return ""
    }
    console.log(`got word: ${word}, params: ${emojiParams}`)
    const url = `${apiUrl}/w/${word}?foreground=${encodeURIComponent(foreground)}&background=${encodeURIComponent(background)}&border=${encodeURIComponent(withBorder)}&border_emoji=${encodeURIComponent(border)}&border_size=${borderSize}`
    console.log(`making request to ${url}`)
    const response = await fetch(url)
    const json = await response.json()
    return json.data
}
