
import React, { useEffect, useState } from 'react'
import * as htmlToImage from 'html-to-image'

import EmojiPickerDialog from '../../components/EmojiPickerDialog'
import { getEmojiText, emojiAPIParams, socketUrl } from './utils'

import Slider from '@mui/material/Slider'

import downloadjs from "downloadjs"


import './style.css'

const loadingIcon = "star"
const errorIcon = "close"

const defaultBackground = "⬜"
const defaultForeground = "👍"
const defaultBorder = "🔥"

type resultLoadingState = "connecting" | "ready" | "waiting_response" | "error"


export default function Emoji() {
  const [word, setWord] = useState("")
  const [emojizedWord, setEmojizedWord] = useState("")
  const [emojiImageUrl, setEmojiImageUrl] = useState("")
  const [backgroundEmoji, setBackgroundEmoji] = useState(defaultBackground)
  const [foregroundEmoji, setForegroundEmoji] = useState(defaultForeground)
  const [withBorder, setWithBorder] = useState<boolean>(false)
  const [borderEmoji, setBorderEmoji] = useState(defaultBorder)
  const [borderSize, setBorderSize] = useState<number>(1)
  const [backgroundEmojiDialogOpen, setBackgroundEmojiDialogOpen] = useState<boolean>(false)
  const [foregroundEmojiDialogOpen, setForegroundEmojiDialogOpen] = useState<boolean>(false)
  const [borderEmojiDialogOpen, setBorderEmojiDialogOpen] = useState<boolean>(false)
  const [loadingState, setLoadingState] = useState<resultLoadingState>('connecting')
  const [useHTTP, setUseHTTP] = useState<boolean>(true)

  const [websocketConnection, setWebsocketConnection] = useState<WebSocket | null>(null);
  const [lastMessage, setLastMessage] = useState('');
  const [clientId, setClientId] = useState<number>(0);


  const getEmojiParams = (): emojiAPIParams => {
    return {
      foreground: foregroundEmoji,
      background: backgroundEmoji,
      withBorder: withBorder,
      border: borderEmoji,
      borderSize: borderSize
    }
  }

  const initSocket = () => {
    // console.log('initializing socket')
    const websocket = new WebSocket(socketUrl);

    websocket.onopen = () => {
      // Generate a unique client ID
      const id = Math.floor(Math.random() * 1000)
      setClientId(id)
      setLoadingState("ready")
    };

    websocket.onmessage = (evt) => {
      const message = (evt.data);
      setLastMessage(message)

    };

    websocket.onclose = () => {
      // console.log('WebSocket is closed');
      setWebsocketConnection(null)
    };

    setWebsocketConnection(websocket);

    return () => {
      websocket.close();
    };



  }

  const cleanupSocket = () => {
    if (websocketConnection == null) return
    websocketConnection.close();
  }

  const testHTTP = () => {
    // console.log('checking the http server')

    // if success
    setLoadingState("ready")

  }

  const getEmojiWordHTTP = (word: string, payload: emojiAPIParams) => {
    // console.log(`sending ${word}, ${JSON.stringify(payload)} thru the http api`)
    getEmojiText(
      word, payload
    ).then(newEmojizedWord => {
      setEmojizedWord(newEmojizedWord)
      setLoadingState("ready")
    }
    ).catch((e) => {
      console.log(`Error in request, ${e}`)
      setLoadingState("error")
    }
    )
  }

  const getEmojiWordWebsocket = (word: string, payload: emojiAPIParams) => {
    // console.log(`sending ${word}, ${JSON.stringify(payload)} thru the socket`)
    if (websocketConnection == null) return
    const ws_payload = JSON.stringify(
      {
        word: word,
        options: {
          foreground: payload.foreground,
          background: payload.background,
          has_border: payload.withBorder,
          border: payload.border,
          border_size: payload.borderSize,
        }
      })
    websocketConnection.send(ws_payload)
  }

  // a new message has been read from the socket
  useEffect(() => {
    if (useHTTP) return
    if (lastMessage == null) return
    // update emoji word
    setEmojizedWord(lastMessage)
    setLoadingState("ready")
  }, [lastMessage])

  // initialize the websocket connection
  useEffect(() => {
    if (useHTTP) {
      cleanupSocket()
      testHTTP()
    } else {
      return initSocket()
    }
  }, [useHTTP])


  const handleDownloadImage = async () => {
    const element = document.getElementById("emoji-display")
    // console.log(`exporting: ${element}`)
    if (element === null) return
    htmlToImage.toPng(element)
      .then((dataUrl) => {
        setEmojiImageUrl(dataUrl)
        downloadjs(dataUrl, `${word}_emoji.png`)
      })
      .catch((error) => {
        console.log(`an error happened: ${error}`)
      })
  }

  // make an api call when things change
  // only if we have finished loading the last change
  useEffect(() => {
    // if (loadingState != "loaded") return
    if (word === "") {
      setEmojizedWord("")
      setLoadingState("ready")
      return
    }
    setLoadingState("waiting_response")
    if (useHTTP) {
      getEmojiWordHTTP(word, getEmojiParams())
    }
    else {
      getEmojiWordWebsocket(word, getEmojiParams())
    }
  }, [
    word,
    foregroundEmoji,
    backgroundEmoji,
    withBorder,
    borderEmoji,
    borderSize,
  ])

  // element that shows when the request to the server is loding
  const LoadingStateIcon = () => {
    switch (loadingState) {
      case "ready":
        return (word.length == 0 ?
          <i className={`nes-icon ${loadingIcon} is-empty`}></i> :
          <i className={`nes-icon ${loadingIcon}`}></i>)
      case "waiting_response":
        return (<i className={`nes-icon ${loadingIcon} is-half`}></i>)
      case "error":
      case "connecting":
      default:
        return (<i className={`nes-icon ${errorIcon}`}></i>)
    }
  }

  const ConnectionPickerComponent = () => {
    return (<div>
      <label>
        <input type="radio" className={"nes-radio"} name="answer" checked={useHTTP}
          onChange={
            () => {
              setUseHTTP(true)
            }
          } />
        <span>http</span>
      </label>

      <label>
        <input type="radio" className={"nes-radio"} name="answer"
          onChange={
            () => {
              setUseHTTP(false)
            }
          }
        />
        <span>WebSocket</span>
      </label>
    </div>)
  }

  const SwapBorder = () => {
    setWithBorder(!withBorder)
  }
  function valuetext(value: number) {
    return `${value}°C`
  }

  const CurrentlySelectedOptions = () => {
    return (
      <div className="current-selection">
        Foreground: {foregroundEmoji} <br />
        Background: {backgroundEmoji} <br />
        {
          withBorder ? (
            <>
              Border: {borderEmoji} <br />
              Border size: {borderSize}
            </>
          ) : (<>No border</>)
        } <br />
        Method: {useHTTP ? "http" : "WebSocket"}
      </div>
    )
  }

  const WordInput = (
    <div className="word-input">
      <label>
        Word:
        <input value={word} onChange={e => {
          setWord(e.target.value)
        }} />
      </label>
    </div>
  )

  const EmojiSelectors = () => {
    return (
      <div className="emoji-selectors">

        {/* Foreground emoji selector */}
        <div id='foreground-picker'>

          <EmojiPickerDialog
            open={foregroundEmojiDialogOpen}
            setOpen={setForegroundEmojiDialogOpen}
            setEmoji={setForegroundEmoji}
            whichEmoji={"Foreground"}
          />
          <button type="button" className="nes-btn is-primary" onClick={() => { setForegroundEmojiDialogOpen(true) }}>
            Change Foreground
          </button>
        </div>

        {/* Background emoji selector */}
        <div id='background-picker'>
          <EmojiPickerDialog
            open={backgroundEmojiDialogOpen}
            setOpen={setBackgroundEmojiDialogOpen}
            setEmoji={setBackgroundEmoji}
            whichEmoji={"Background"}
          />
          <button type="button" className="nes-btn is-primary" onClick={() => { setBackgroundEmojiDialogOpen(true) }}>
            Change Background
          </button>
        </div>

        <div id='border-picker'>
          {withBorder ? (
            <>
              <button type="button" className="nes-btn is-primary" onClick={() => { setWithBorder(false) }}>
                Remove Border
              </button>
              <EmojiPickerDialog
                open={borderEmojiDialogOpen}
                setOpen={setBorderEmojiDialogOpen}
                setEmoji={setBorderEmoji}
                whichEmoji={"Border"}
              />
              <button type="button" className="nes-btn is-primary" onClick={() => { setBorderEmojiDialogOpen(true) }}>
                Change Border
              </button>
              <Slider
                aria-label="Temperature"
                defaultValue={5}
                getAriaValueText={valuetext}
                valueLabelDisplay="auto"
                shiftStep={1}
                step={1}
                marks
                min={1}
                max={10}
                value={borderSize} onChange={
                  (event: Event, newValue: number | number[]) => {
                    setBorderSize(newValue as number)
                  }
                }
              />
            </>
          ) : (
            <>
              <button type="button" className="nes-btn is-primary" onClick={() => { setWithBorder(true) }}>
                Set Border
              </button>

            </>)}
        </div >

        <div id='method-picker'>
          <button type="button" className="nes-btn is-primary" onClick={() => {
            setUseHTTP(!useHTTP)
          }}>
            Change Method
          </button>
        </div>
      </div>
    )
  }

  const EmojizedWord = () => {
    return (<p>
      {
        emojizedWord
          .split('\n')
          .map((row, i) => <div key={i}>{row}</div>)
      }
    </p>)
  }

  return (
    <div className="app ">
      <div className="title nes-container is-rounded">
        <h1>Emoji Writer</h1>

        <div className='status-icon'>
          <LoadingStateIcon />
        </div>
      </div>
      <div className="display nes-container is-rounded">
        <EmojizedWord />
      </div>
      <div className="controls nes-container is-rounded">
        <CurrentlySelectedOptions />
        {WordInput}
        <EmojiSelectors />
      </div>
    </div >
  )
}