import { withEmotionCache } from '@emotion/react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import EmojiPicker from 'emoji-picker-react';




export interface EmojiPickerDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void
    setEmoji: (emoji: string) => void
    whichEmoji: string
  }

export default function EmojiPickerDialog(props: EmojiPickerDialogProps) {
    const { open, setOpen, setEmoji, whichEmoji } = props

    return (
        <Dialog open={open} onClose={() => {setOpen(false)}} >
            <DialogTitle>select emoji for {whichEmoji}</DialogTitle>
            <div>
                <EmojiPicker onEmojiClick={(emoji) => {
                    setEmoji(emoji.emoji)
                    setOpen(false)
                }}/>
            </div>
        </Dialog>
   
    )
}
