import { BrowserRouter, Routes, Route } from "react-router-dom"


import './App.css'
import Main from './views/main/main'
import Emoji from './views/emoji/emoji'


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" >
          <Route index element={<Main />} />
          <Route path="emoji" element={<Emoji />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
