import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import './style.css'
import "nes.css/css/nes.min.css";
import LoadingScreen from '../../components/LoadingBar';



export default function Main() {

  const [nightMode, setNightMode] = useState<boolean>(false)
  const [loaded, setLoaded] = useState<boolean>(true)
  const navigate = useNavigate()

  if (!loaded) {
    return (
      <div>
        <LoadingScreen loaded={loaded} setLoaded={setLoaded} />
      </div>
    )
  }

  return (
    <div className="main-container">
      <div className="main-presentation-txt">
        <div className={nightMode ? "nes-container is-dark with-title" : "nes-container with-title"}>
          <p className="title">Hello!</p>
          <p>
            This is Pablo! I'm reworking my website, now using react and self-hosted!
          </p>
          <p>
            I'm a software engineer based in Oakland, CA, originally from Barcelona, Spain.

            Currently I work at Zoox, building metrics pipelines for the driving behavior team.
            <br />
            Previously I lead the Data Engine team at Embark Trucks, where we built data pipelines and tools for the Deep Learning team.
            <br />
            Before that I was a founding engineer at CrowdAI (acquired by Saab), where I trained neural networks to understand satellite images and I built the pipelines that allowed to train and deploy the models.
          </p>
          <p>
            In this website I'll post some of my projects and dummy ideas. Some examples:
            <ul className={nightMode ? "nes-list is-circle" : "nes-list is-disc"}>
              <li><a onClick={() => { navigate('/emoji') }}>🎉🚀Emoji writer🔥😻</a></li>
              {/* <li><a onClick={() => {navigate('/job_hunt')}}>Notes about my job hunt</a></li> */}
            </ul>
          </p>
        </div>
      </div>
      <div className="main-bottom">
        <div className="main-links">
          <a href='https://github.com/pllueca/' className="nes-icon github is-large"></a>
          <a href='https://www.linkedin.com/in/pllueca/' className="nes-icon linkedin is-large"></a>
          <a href='https://twitter.com/pllueca/' className="nes-icon twitter is-large"></a>
        </div>
        <div className="main-controls">
          {/* <button type="button" className="nes-btn is-primary" onClick={() => {setNightMode(!nightMode)}}>Change Mode</button>
          <button type="button" className="nes-btn is-primary" onClick={() => { window.location.reload() }}>Reset</button> */}
        </div>
      </div>
    </div>
  );
}