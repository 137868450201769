
import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import "nes.css/css/nes.min.css";
import "./style.css"


type LoadingScreenProps = {
    loaded: boolean
    setLoaded: (x:boolean)=> void
}

const DEFAULT_INCREMENT = 10
const MAX_PROGRESS = 100

export default function LoadingScreen(props: LoadingScreenProps) {
    const { loaded, setLoaded} = props
    const [progress, setProgress] = useState<number>(0)
    const progressFinished: () => boolean = useCallback(() => {return progress >= MAX_PROGRESS}, [progress])
    
    useCallback(() => {
        const interval = setInterval(() => {
            setProgress(
                (progress) => {
                    const nProgress = progress + DEFAULT_INCREMENT
                    if (nProgress >= MAX_PROGRESS) return MAX_PROGRESS
                    return nProgress 
                }
            )
            if (progressFinished()) {
                clearInterval(interval)
            }
        }, 100)
        return interval
    }, [progress])()


    const doneLoading = useCallback(() => {
        if(!progressFinished || loaded) return
        setLoaded(true)
    }, [setLoaded, loaded, progressFinished])

    // pressing any key will continue after loading is done
    const handleKeyPress = useCallback( () => {doneLoading()} ,[doneLoading])

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress)
        return () => {
          document.removeEventListener('keydown', handleKeyPress)
        }
      }, [handleKeyPress])

    return (
    <div className='loading-page-main'>
        <progress className={ "nes-progress " + (loaded ? "is-success" : "is-primary") } value={ progress } max="100"></progress>
        {
        progressFinished() ? (
        <div>
            <button
            className='nes-btn is-primary'
            onClick={() => {
                doneLoading()
            }}>Press any key to continue</button>
        </div>
        ) : <p>Loading ...</p>
        }
    </div>)

}